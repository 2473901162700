<template>
  <div class="emb-privacy-policy-wrap">
    <div class="d-flex justify-center text-h4 ma-6 pt-3 font-weight-bold">
      {{ $t("message.aboutPage.title") }}
    </div>

    <div class="section-gap bg-white">
      <div class="container">
        <div class="privacy-page">
          <div
            class="emb-card mb-12 pa-6"
            v-for="(about, key) in aboutLocale"
            :key="key"
          >
            <div class="text-h5 mb-4">{{ about.name }}</div>
            <div v-html="about.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aboutData from "Assets/data/about";
export default {
  data() {
    return {
      aboutLocale: [],
      aboutData,
    };
  },
  watch: {
    "$i18n.locale": function () {
      this.getAboutData();
    },
  },
  mounted() {
    this.getAboutData();
  },
  methods: {
    getAboutData() {
      this.aboutLocale = this.aboutData[this.$i18n.locale];
    },
  },
};
</script>
