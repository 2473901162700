export default {
    "fr": [
        {
            "name": "Qui sommes-nous",
            "content": "Nous sommes un service de messagerie de billets de loterie en ligne offrant aux clients la possibilité de jouer aux deux plus grandes loteries des États-Unis, avec des billets de loterie officiels, de n'importe où dans le monde.  En tant que service de messagerie indépendant, nous vous donnons la possibilité d'acheter et de participer légalement aux deux loteries les plus excitantes de la planète depuis votre ordinateur ou votre téléphone portable.  Le site Web est exploité par Blackdanes, une société enregistrée en FLORIDE, aux États-Unis."
        },
        {
            "name": "Comment ça fonctionne",
            "content": "Inscrivez-vous pour vous créer un compte gratuit. Ensuite, choisissez la loterie à laquelle vous voulez jouer, choisissez vos numéros et commandez votre billet.<br>Nos représentants en Floride achèteront des billets officiels en votre nom.<br>Vos billets seront attribués et numérisés sur votre compte personnel avant le tirage et nous les conserverons pour vous dans un coffre-fort afin que VOTRE billet soit valide et ne soit jamais perdu.  Votre billet restera sécurisé et il ne quittera jamais le territoire des États-Unis.  Selon les lois américaines, un billet ne doit jamais quitter le territoire américain pour rester valide.<br>Nous vous informerons par e-mail lorsque vous gagnerez. Vos gains vous seront crédités sous forme de crédit ou ils vous seront remis par transfert bancaire, par courrier ou en personne en Floride."
        },
        {
            "name": "Notre modèle d'affaires",
            "content": "Nous achetons des billets de loterie officiels au nom de nos clients et nos revenus proviennent uniquement des frais de service inclus avec la vente de ces billets. Nous ne prenons donc AUCUNE commission sur vos gains."
        },
        {
            "name": "Simple et sécuritaire",
            "content": " -Vos transactions en ligne sont protégées.<br>-Nous nous engageons à 100% pour vous offrir un service fiable.<br>-La copie scannée prouve l'existence et votre propriété du billet<br>-Support client en français et anglais via Live Chat, email et réseaux sociaux.<br>-Vous êtes le seul propriétaire de votre billet.<br>-Paiements rapides et sûrs."
        },
        {
            "name": "Des questions?",
            "content": "Vous avez des questions sur notre service ou votre compte ?  N'hésitez pas à contacter notre service client ou consultez notre FAQ pour plus d'informations."
        }
    ],
    "en": [
        {
            "name": "Who We Are",
            "content": "We are an online concierge service providing customers with the opportunity to play the two biggest lotteries in the USA, with official lottery tickets, from anywhere in the world. As an independent concierge service, we give you the ability to purchase and legally participate in the two most exciting lotteries on the planet from your computer or mobile phone. The website is operated by Blackdanes LLC, a company registered in FLORIDA, USA."
        },
        {
            "name": "How It Works",
            "content": "Sign up for a free account.<br>Choose the lottery you want to play, pick your numbers, and order your ticket.<br>Our representatives in Florida will receiver the order and purchase official tickets on your behalf.<br>Your tickets will be scanned and assigned to your personal account before the draw and stored for you in a safe so YOUR ticket will never be lost. Your ticket will remain secure, and never leaves the United States territory. According to the laws, a ticket must never leave the territory to remain valid.<br>We will notify you by email when you win.<br>Receive your winnings in credit, interact (Zelle), or by mail."
        },
        {
            "name": "Our Business Model",
            "content": "We purchase official lottery tickets on behalf of our customers and our revenue stems solely from a service fee included with the sale of these tickets."
        },
        {
            "name": "Simple and Secure",
            "content": "-Your transactions are protected<br>-You can only buy with the major credit cards company. We are 100% dedicated to give you a reliable service. <br>-Scanned copy proves the existence and your ownership of the ticket<br>-Customer support in french and english via Live Chat, email and social media. <br>-You are the sole owner of your ticket. <br>-Fast and safe payouts."
        },
        {
            "name": "Questions?",
            "content": "Have questions about our service or your account? Don't hesitate to contact our customer service or check our FAQ for additional information."
        }
    ]
}